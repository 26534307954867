import { Avatar, Badge, Paper, TextField, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useStyles } from './style'
import { AccountAction, AccountState } from '../state'
import Grid from '@material-ui/core/Grid'
import { useState } from '@hookstate/core'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import SimpleTabs from './Tab'
import { formatDateToDisplay, isScreenBiggerThan } from '../../../utils/parse'
import { useMobileStyles } from './mobileStyle'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { getObj } from '../../../utils/hookState'
import { DATE_DISPLAY_FORMAT } from '../../../constant'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import BackupIcon from '@material-ui/icons/Backup'

export const Profile = () => {
  const classes = useStyles()
  const classesMobile = useMobileStyles()
  const { t, i18n } = useTranslation()

  const profileState = useState(AccountState.profile)
  const [isEditing, setIsEditing] = React.useState(false)
  const [usernameToSet, setUsernameToSet] = React.useState('')
  const [bioToSet, setBioToSet] = React.useState('')
  const [fileState, setFileState] = React.useState({ file: '', imagePreviewUrl: '' })

  useEffect(() => {
    AccountAction.getProfile()()
  }, [])

  const handleClickEdit = () => {
    setUsernameToSet(getObj(profileState)?.username ? getObj(profileState)?.username : '')
    setBioToSet(getObj(profileState)?.bio ? getObj(profileState)?.bio : '')

    setIsEditing(true)
  }
  const handleClickCancelEdit = () => {
    setIsEditing(false)
  }
  const handleClickDoneEdit = () => {
    setIsEditing(false)
    AccountAction.updateUserInfo(usernameToSet, bioToSet, fileState.imagePreviewUrl)()
    setTimeout(function () {
      window.location.reload()
    }, 2000)
  }
  const handleUsernameChange = (event) => {
    if (event.target.value != null) {
      setUsernameToSet(event.target.value as string)
    }
  }

  const handleBioChange = (event) => {
    if (event.target.value != null) {
      setBioToSet(event.target.value as string)
    }
  }

  const handleDiscardAvatar = (e) => {
    e.preventDefault()
    console.log('handle uploading-', fileState.imagePreviewUrl)
    setFileState({ file: '', imagePreviewUrl: '' })
  }

  const handleImageChange = (e) => {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      setFileState({
        file: file,
        // @ts-ignore
        imagePreviewUrl: reader.result,
      })
    }

    reader.readAsDataURL(file)
  }

  return isScreenBiggerThan('md') ? (
    <Grid container spacing={3}>
      <Grid item xs={12} md={9}>
        <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={3} md={3}>
              {isEditing ? (
                <>
                  {fileState.imagePreviewUrl !== '' ? (
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        <CancelIcon className={classes.discardAvatarButton} onClick={handleDiscardAvatar} />
                      }
                    >
                      <Avatar src={fileState.imagePreviewUrl} className={classes.avatar} />
                    </Badge>
                  ) : (
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        <Button className={classes.uploadAvatarButton} component="label">
                          <BackupIcon className={classes.uploadIcon} />
                          <input className="fileInput" type="file" hidden onChange={handleImageChange} />
                        </Button>
                      }
                    >
                      <Avatar src={getObj(profileState).avatar} className={classes.avatar} />
                    </Badge>
                  )}
                </>
              ) : (
                <Avatar src={getObj(profileState).avatar} className={classes.avatar} />
              )}
            </Grid>

            <Grid className={classes.usernameGrid} item xs={9} md={9}>
              <Grid container spacing={3}>
                <Grid item xs={8} md={8}>
                  {isEditing ? (
                    <TextField
                      className={classes.username}
                      fullWidth
                      variant="outlined"
                      value={usernameToSet}
                      onChange={handleUsernameChange}
                    />
                  ) : (
                    <Typography className={classes.username} variant="body2" gutterBottom>
                      {getObj(profileState).username}
                    </Typography>
                  )}

                  <Typography className={classes.joinTime} variant="body2" gutterBottom>
                    {t('Member from')}: {formatDateToDisplay(getObj(profileState).created_at, DATE_DISPLAY_FORMAT)}
                  </Typography>
                </Grid>

                <Grid container item xs={4} md={4} justify="center" alignItems={'center'}>
                  <div>
                    {isEditing ? (
                      <>
                        <Button className={classes.cancelButton} variant={'contained'} onClick={handleClickCancelEdit}>
                          {t('Cancel')}
                        </Button>
                        <Button className={classes.doneButton} variant={'contained'} onClick={handleClickDoneEdit}>
                          {t('Done')}
                        </Button>
                      </>
                    ) : (
                      <Button className={classes.editButton} variant={'contained'} onClick={handleClickEdit}>
                        {t('Edit')}
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Divider />
              <div className={classes.bioGrid}>
                {isEditing ? (
                  <TextField
                    className={classes.bioEditing}
                    fullWidth
                    multiline
                    variant="outlined"
                    rows={3}
                    value={bioToSet}
                    onChange={handleBioChange}
                  />
                ) : (
                  <Typography className={classes.bio} variant="body2" gutterBottom>
                    {getObj(profileState).bio}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>

          <SimpleTabs />
        </Paper>
      </Grid>
      {/*<Grid item xs={12} md={3}>*/}
      {/*  /!*<EducationTable />*!/*/}
      {/*  /!*<BoringList />*!/*/}
      {/*</Grid>*/}
    </Grid>
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12} md={9}>
        <Paper className={classesMobile.paper} elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={3} md={3}>
              <Avatar className={classesMobile.avatar} src={getObj(profileState).avatar} />
            </Grid>

            <Grid className={classesMobile.usernameGrid} item xs={9} md={9}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Typography className={classesMobile.username} variant="body2" gutterBottom>
                    {getObj(profileState).username}
                  </Typography>
                  <Typography className={classesMobile.joinTime} variant="body2" gutterBottom>
                    {t('Member from')}: {formatDateToDisplay(getObj(profileState).created_at, DATE_DISPLAY_FORMAT)}
                  </Typography>
                </Grid>

                <Grid container item xs={3} justify="center" alignItems={'center'}>
                  <div>
                    {isEditing ? (
                      <IconButton
                        className={classesMobile.editButton}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClickDoneEdit}
                      >
                        <CheckIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classesMobile.editButton}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClickEdit}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Divider />

              <Typography className={classesMobile.bio} variant="body2" gutterBottom>
                {getObj(profileState).bio}
              </Typography>
            </Grid>
          </Grid>

          <SimpleTabs />
        </Paper>
      </Grid>
    </Grid>
  )
}
