// @ts-nocheck

import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Profile } from "../components/Account/Profile"

export default () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Profile />
      {/*<Router>*/}
      {/*  <Profile path={`${ROUTES.PROFILE}/:slug`} />*/}
      {/*  /!*<Profile path={`${ROUTES.PROFILE}`} />*!/*/}
      {/*</Router>*/}
    </Layout>
  )
}
