import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useMobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      marginBottom: '1rem',
      marginTop: '3rem',
    },
    usernameGrid: {
      marginTop: theme.spacing(0),
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
      margin: theme.spacing(2),
    },
    username: {
      fontWeight: 700,
      fontSize: '1.2rem',
    },
    joinTime: {
      color: 'rgb(160, 160, 160)',
      // fontWeight: 700,
      // fontSize: '2rem'
    },
    detailGreyTypo: {
      color: 'rgb(160, 160, 160)',
      // fontWeight: 700,
      // fontSize: '2rem'
      margin: theme.spacing(2),
    },
    detailTypo: {
      // fontWeight: 700,
      // fontSize: '2rem'
      margin: theme.spacing(2),
    },

    bio: {
      marginTop: theme.spacing(1),
      // fontWeight: 700,
      fontSize: '1rem',
    },
    editButton: {
      // width: theme.spacing(20),
      // marginLeft: theme.spacing(2),
    },
    tabRoot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
      color: CONF.css.black,
    },
  })
)
