import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { useStyles } from './style'
import { useState } from '@hookstate/core'
import { AccountAction, AccountState } from '../state'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import { TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { getObj } from '../../../utils/hookState'
import { useTranslation } from 'react-i18next'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/*<Typography>{children}</Typography>*/}
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function SimpleTabs() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [tabNumber, setTabNumber] = React.useState(0)
  const profileState = useState(AccountState.profile)

  const [tlgUsername, setTlgUsername] = React.useState('')
  const [isEditingTlg, setIsEditingTlg] = React.useState(false)

  // console.log(`========tlgUsername=======${tlgUsername}`)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNumber(newValue)
  }

  const handleClickEditTlg = () => {
    setTlgUsername(getObj(profileState)?.tlg_username ? getObj(profileState)?.tlg_username : '')
    setIsEditingTlg(true)
  }
  const handleClickDoneEditTlg = () => {
    setIsEditingTlg(false)
    AccountAction.updateTlg(tlgUsername)()
    setTimeout(function () {
      window.location.reload()
    }, 2000)
  }

  const handleTextFieldChange = (event) => {
    if (event.target.value != null) {
      // console.log(`========tlgUsername=======${tlgUsername}`)
      setTlgUsername(event.target.value as string)
      // console.log(`========tlgUsername=======${tlgUsername}`)
    }
  }

  return (
    <div className={classes.tabRoot}>
      <AppBar className={classes.appBar} position="static" elevation={1}>
        <Tabs
          value={tabNumber}
          indicatorColor="primary"
          onChange={handleTabChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label={t('Info')} {...a11yProps(0)} />
          <Tab label={t('Post')} {...a11yProps(1)} />
          <Tab label={t('Follower')} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabNumber} index={0}>
        {/*<Typography className={classes.detailGreyTypo}>{t('Display Name')}</Typography>*/}
        {/*<Typography className={classes.detailTypo}>{getObj(profileState).username}</Typography>*/}
        {/*<Typography className={classes.detailGreyTypo}>*/}
        {/*  Tên hiển thị sẽ được hiển thị công khai. Bạn nên dùng họ tên đầy đủ của mình.*/}
        {/*</Typography>*/}

        {/*<Divider />*/}
        <Typography className={classes.detailGreyTypo}>Email</Typography>
        <Typography className={classes.detailTypo}>{getObj(profileState).email}</Typography>

        {/*<Divider />*/}
        {/*<Typography className={classes.detailGreyTypo}>Password</Typography>*/}
        {/*<Typography className={classes.detailTypo}>{profileState.email.get()}</Typography>*/}

        <Divider />
        <Grid container item>
          <Typography className={classes.telegramLongText}>{t('PROFILE_TELEGRAM')}</Typography>
          <Grid container item xs={11} justify={'flex-start'} direction={'column'}>
            <Typography className={classes.detailGreyTypo}>{t('Telegram Username')}</Typography>

            {isEditingTlg ? (
              <TextField className={classes.detailTypo} variant="outlined" value={tlgUsername} onChange={handleTextFieldChange} />
            ) : (
              <Typography className={classes.detailTypo}>{getObj(profileState).tlg_username}</Typography>
            )}
          </Grid>
          <Grid container item xs={1}>
            {isEditingTlg ? (
              <Button onClick={handleClickDoneEditTlg}>
                <CheckIcon fontSize={'large'} style={{ color: 'green' }} />
              </Button>
            ) : (
              <Button onClick={handleClickEditTlg}>
                <EditIcon fontSize={'large'} />
              </Button>
            )}
          </Grid>
        </Grid>

        <Divider />
      </TabPanel>
      <TabPanel value={tabNumber} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={tabNumber} index={2}>
        Item Three
      </TabPanel>
    </div>
  )
}
