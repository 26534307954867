import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      marginBottom: '1rem',
      marginTop: '3rem',
    },
    usernameGrid: {
      marginTop: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
    cameraIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: CONF.css.blue,
      cursor: 'pointer',
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(3),
      margin: theme.spacing(2),
    },
    username: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    joinTime: {
      color: 'rgb(160, 160, 160)',
      // fontWeight: 700,
      // fontSize: '2rem'
    },
    telegramLongText: {
      color: CONF.css.orange,
      // fontWeight: 700,
      // fontSize: '2rem'
      margin: theme.spacing(2),
    },
    detailGreyTypo: {
      color: 'rgb(160, 160, 160)',
      // fontWeight: 700,
      // fontSize: '2rem'
      margin: theme.spacing(2),
    },
    detailTypo: {
      // fontWeight: 700,
      // fontSize: '2rem'
      margin: theme.spacing(2),
      marginTop: theme.spacing(0),
    },

    bio: {
      marginTop: theme.spacing(1),
      // fontWeight: 700,
      fontSize: '1rem',
    },
    bioEditing: {
      marginTop: theme.spacing(1),
      // marginRight: theme.spacing(2),
      // fontWeight: 700,
      fontSize: '1rem',
    },
    editButton: {
      width: theme.spacing(20),
      marginLeft: theme.spacing(2),
    },
    bioGrid: {
      paddingRight: theme.spacing(2),
    },
    uploadIcon: {
      color: CONF.css.blue,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    uploadAvatarButton: {
      borderRadius: 8000000,
    },
    discardAvatarButton: {
      // height: theme.spacing(3),
      // backgroundColor: CONF.css.orange,
      // marginLeft: theme.spacing(13),
      width: theme.spacing(5),
      height: theme.spacing(5),
      // backgroundColor: CONF.css.red
      color: CONF.css.red,
      cursor: 'pointer',
    },
    cancelButton: {
      // width: theme.spacing(20),
      backgroundColor: CONF.css.orange,
      marginLeft: theme.spacing(1),
    },
    doneButton: {
      // width: theme.spacing(20),
      backgroundColor: CONF.css.green,

      marginLeft: theme.spacing(1),
    },
    tabRoot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
      color: CONF.css.black,
    },
  }),
)
